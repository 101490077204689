import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'autosize-textarea',
  templateUrl: './autosize-textarea.component.html',
  styleUrls: ['./autosize-textarea.component.scss']
})
export class AutosizeTextareaComponent implements OnChanges, AfterViewInit {

  @Input()
  text: any;

  @Input()
  placeholder: any;

  @Input()
  size: 'md' | 'sm' | 'lg' = 'md';

  @Input()
  readonly = false;

  @Output()
  textChange = new EventEmitter<any>();

  innerText: any;

  @ViewChild('editableDiv')
  editableDiv: ElementRef;

  ngOnChanges() {
    {
      if (this.text != this.innerText && this.editableDiv) {
        this.editableDiv.nativeElement.innerText = this.text;
        this.innerText = this.text;
      }
    }
  }

  ngAfterViewInit() {
    if (this.text != this.innerText) {
      this.editableDiv.nativeElement.innerText = this.text;
      this.innerText = this.text;
    }
  }

  onInput(e) {
    var newValue = e.target.innerText;
    // e.target.textContent = e.target.textContent || '';
    this.text = newValue;
    this.innerText = newValue;
    this.textChange.next(newValue);
  }

  onKeydown(e) {
    // if (e.keyCode == 13) {
    //   //don't automatically put in divs
    //   e.preventDefault();
    //   e.stopPropagation();
    //   //insert newline
    //   this.insertTextAtSelection(e.target, "\n");
    // }
  }

  onPaste(e) {
    e.preventDefault();
    //get plaintext from clipboard
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
    //insert text manually
    this.insertTextAtSelection(e.target, text);
  }

  insertTextAtSelection(div, txt) {
    //get selection area so we can position insert
    let sel = window.getSelection();
    let text = div.textContent;
    let before = Math.min(sel.focusOffset, sel.anchorOffset);
    let after = Math.max(sel.focusOffset, sel.anchorOffset);
    //ensure string ends with \n so it displays properly
    let afterStr = text.substring(after);
    if (afterStr == "") afterStr = "\n";
    //insert content
    div.textContent = text.substring(0, before) + txt + afterStr;
    //restore cursor at correct position
    sel.removeAllRanges();
    let range = document.createRange();
    //childNodes[0] should be all the text
    range.setStart(div.childNodes[0], before + txt.length);
    range.setEnd(div.childNodes[0], before + txt.length);
    sel.addRange(range);
  }
}
